<template>
	<div>
		<div class="content content_top_margin" style="min-height: 623px;">
			<div class="content_inner  ">
				<div class="full_width ">
					<div class="full_width_inner">

						<div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636145816546 grid_section"
							style=" text-align:left;">
							<div class=" section_inner clearfix">
								<div class="section_inner_margin clearfix">
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="wpb_text_column wpb_content_element ">
													<div class="wpb_wrapper">
														<h1 class="info-popup-wrapper">Discover the world<br>
															of ergo <span class="info-popup">
																<img src="../../assets/images/airbnb/info-popup.png"
																	alt="Hover for info">
																<em>Ergonomics helps us
																	improve our comfort and productivity in our work
																	environment, no matter where we are!</em></span>
														</h1>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 50px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  info-boxes vc_custom_1637278725305"
													style=" text-align:left;">
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-12">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h2><strong>Where are you working
																					today?</strong></h2>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 50px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'working-from-home-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="422"
																						src="../../assets/images/airbnb/Airbnb-Landing-page-working-from-home-.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb-Landing-page---working-from-home---">
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h4>
																				<router-link :to="{ name: 'working-from-home-a45' }"> 
																					Working from home 
																				</router-link>
																			</h4>
																			<p>Discover the best ways to bring ergo
																				into your home, boost productivity
																				and feel better!</p>
																			<p><em>10 minute journey</em></p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			
																			<router-link :to="{ name: 'working-from-the-office-a45' }"> 
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="2500" height="1665"
																						src="../../assets/images/airbnb/New-Working-from-Office.png"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="New Working from Office">
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h4><router-link :to="{ name: 'working-from-the-office-a45' }"> Working
																					from the office</router-link></h4>
																			<p>Discover your ideal desk setup,
																				personalize your equipment, boost
																				productivity and feel better!</p>
																			<p><em>10 minute journey</em></p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'i-am-hybrid-a45' }"> 
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="633" height="422"
																						src="../../assets/images/airbnb/Airbnb-Landing-Page-Hybrid-1.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb-Landing-Page---Hybrid">
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h4><router-link :to="{ name: 'i-am-hybrid-a45' }"> Hybrid
																					working</router-link></h4>
																			<p>Are you doing the hybrid thing?
																				Working home and the office? We’ve
																				got you.</p>
																			<p><em>10 minute journey</em></p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'working-in-groups-a45' }"> 
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="2000" height="1500"
																						src="../../assets/images/airbnb/New-working-in-groups.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="New working in groups">
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h4><router-link :to="{ name: 'working-in-groups-a45' }"> Working
																					in group settings </router-link></h4>
																			<p>Discover how to make ergo an
																				important and valuable member of
																				your team.</p>
																			<p><em>5 minute journey</em></p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'working-on-the-move-a45' }"> 
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="422"
																						src="../../assets/images/airbnb/Airbnb-Landing-Page-On-the-Move.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb-Landing-Page---On-the-Move">
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h4><router-link :to="{ name: 'working-on-the-move-a45' }">  Working
																					on the move </router-link> </h4>
																			<p>Wanderlust catches us all. Try these
																				tips to bring ergo everywhere.</p>
																			<p><em>5 minute journey</em></p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'working-from-listings-a45' }"> 
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="632" height="422"
																						src="../../assets/images/airbnb/Airbnb-landing-Page-Working-From-Listing-1.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb-landing-Page---Working-From-Listing">
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h4><router-link :to="{ name: 'working-from-listings-a45' }">  Working
																					from an Airbnb </router-link>
																					</h4>
																			<p>Discover how to bring ergo as you
																				work from our listings.</p>
																			<p><em>5 minute journey</em></p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636145552804 grid_section"
							style=" text-align:left;"  v-show="company_code">
							<div class=" section_inner clearfix">
								<div class="section_inner_margin clearfix">
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_row wpb_row section vc_row-fluid vc_inner  cta-banner vc_custom_1636145353757"
													style=" text-align:left;">
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-12">
															<div class="vc_column-inner vc_custom_1636145566308">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h2>Need more help?</h2>
																			<p>Take a self-assessment today and
																				see<br>
																				how ergo can help you feel better
																				and<br>
																				work smarter.</p>
																			<p>
																				<router-link :to="{ name: 'get-an-assessment-a45' }" class="button"> 
																					Take a self-assessment 
																				</router-link>
																			</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>



					</div>
				</div>


			</div>
		</div>
	</div>
</template>
<script>
	//import carousel from "vue-owl-carousel"
	export default {
		name: 'home_new',
		data () {
			return {
			company_code: true,      
			}
		},
		mounted(){
			document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
			let data = this.$store.getters["auth/authUser"];
			let company_data = data.client;
			if(company_data.self_assessment_button_status == 0){
			this.company_code = false;
			}
		},
	}
</script>